<template>
	<div>
	
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid  vc_custom_1636151250857 grid_section"
							style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_row wpb_row section vc_row-fluid vc_inner  single_top_row"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-6">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h1>Ergo equipment guide<br />
																				Lighting</h1>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-6">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<p>
																				<router-link :to="{ name: 'ergo-accessories-a45' }">
																						<em>Up
																						Next: </em> Accessories <img
																						class="vc_single_image-img attachment-full"
																						src="../../assets/images/airbnb/Airbnb-Arrows-2.png"
																						alt="" width="64"
																						height="37" />
																				</router-link>
																			</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_raw_code wpb_content_element wpb_raw_html">
													<div class="wpb_wrapper">
														<div class="equipment_image_wrapper">
															<img src="../../assets/images/airbnb/Airbnb-Equipment-Main-Page.jpg"
																alt="Equipment Guides" />


															<router-link :to="{ name: 'mobile-devices-a45' }" id="equipment-icon-mobile-devices" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Mobile devices" />
															</router-link>

															<router-link :to="{ name: 'screens-a45' }" id="equipment-icon-screens" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Screens" />
															</router-link>

															<router-link :to="{ name: 'keyboard-mouse-eq-a45' }" id="equipment-icon-keyboard-mouse" class="equipment-icon current">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Keyboard and Mouse" />
															</router-link>

															<router-link :to="{ name: 'chairs-a45' }" id="equipment-icon-chairs" class="equipment-icon flipped">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Chairs" />
															</router-link>

															<router-link :to="{ name: 'lighting-a45' }" id="equipment-icon-lighting" class="equipment-icon">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Lighting" />
															</router-link>

															<router-link :to="{ name: 'ergo-accessories-a45' }" id="equipment-icon-ergo-accessories" class="equipment-icon flipped">
																<img src="../../assets/images/airbnb/Airbnb-Equipment-Graphic.png" alt="Ergo accessories" />
															</router-link>
															<div class="equipment-desc">
																<h3>Lighting</h3>
																<ul>
																	<li>Natural lighting is ideal</li>
																	<li>Sit perpendicular to windows</li>
																	<li>Avoid glare on your screens</li>
																	<li>Avoid flickering lights</li>
																	<li>Avoid blue light especially at night</li>
																</ul>
															</div>

														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner "
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left   rounded">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="700" height="467"
																					src="../../assets/images/airbnb/Airbnb-Equipmnet-Lighting-1.jpg"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipmnet---Lighting" />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-8">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<h2>Lighting is essential&#8230;</h2>
																			<p>. . . for you to see, for you to function
																				at work and for you to steer clear of
																				eye strain and headaches. Good lighting
																				also helps you have more energy, make
																				less errors and stay mentally alert.</p>
																			<p>Some things to consider include the
																				source of light such as light emitted by
																				screens and bulbs vs. light that is
																				reflected (think paper, to do lists,
																				etc.), access to natural light and the
																				time of day that you are working.</p>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 52px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  two_cols"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div
															class="wpb_column vc_column_container vc_col-sm-3 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="55" height="50"
																					src="../../assets/images/airbnb/Airbnb-Equipment-Lighting-Set-up.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipment---Lighting---Set-up" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637041486872">
																		<div class="wpb_wrapper">
																			<h4>Set-up</h4>
																			<p>&nbsp;</p>
																			<ul>
																				<li>It is best to set yourself up to be
																					perpendicular to windows.</li>
																				<li>Low glare task lamps are great for
																					reading and writing.</li>
																				<li>A mixture of natural, indirect and
																					direct lighting is best.</li>
																				<li>Blinds and drapes help control your
																					lighting and glare.</li>
																			</ul>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="wpb_column vc_column_container vc_col-sm-9 vc_col-has-fill">
															<div class="vc_column-inner rounded_border-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">

																			<div
																				class="vc_single_image-wrapper   vc_box_border_grey">
																				<img width="64" height="50"
																					src="../../assets/images/airbnb/Airbnb-Equipment-Lighting-Eye-Savers.png"
																					class="vc_single_image-img attachment-full"
																					alt="" loading="lazy"
																					title="Airbnb---Equipment---Lighting---Eye-Savers" />
																			</div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 32px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  no-margin vc_custom_1637041525445">
																		<div class="wpb_wrapper">
																			<h4>Eye Savers</h4>
																			<p>&nbsp;</p>
																			<ul>
																				<li><strong>Adopt the 20/20/20
																						rule.</strong> Every 20 minutes
																					look at least 20 feet (6 meters)
																					away and hold for at least 20
																					seconds.</li>
																				<li><strong>Blink more.</strong>
																					Seriously. When we stare at screens
																					our blink rate drops by 50%. So make
																					a conscious effort to blink more.
																				</li>
																				<li><strong>Avoid flickering.</strong>
																					Whether it’s your monitor, devices
																					or fluorescent lighting, make sure
																					there is no flicker whatsoever.</li>
																				<li><strong>Get your eyes
																						checked.</strong> If you find
																					yourself squinting, leaning forward
																					to see or straining, it may be time
																					to get an eye exam.</li>
																			</ul>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					<div class="vc_row wpb_row section vc_row-fluid  grid_section" style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>


												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h2>All equipment guides</h2>

													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  continue_block"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'screens-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="700" height="467"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Screens-Main-Page.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb-Equipment---Screens---Main-Page" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'screens-a45'}">
																			<h4>Screens</h4>
																			<p>Wanna have great posture and be
																				productive? Learn how.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'keyboard-mouse-eq-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="700" height="600"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Keyboard-Mouse.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment---Keyboard-Mouse" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'keyboard-mouse-eq-a45'}">
																			<h4>Keyboard &amp; mouse</h4>
																			<p>Discover the best technique to mouse and
																				type.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'chairs-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="700" height="467"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Chairs.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment---Chairs" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'chairs-a45'}">
																			<h4>Chairs</h4>
																			<p>Ergo has your back no matter where you
																				sit.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'lighting-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Equipmnet-Lighting.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipmnet---Lighting" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'lighting-a45'}">
																			<h4>Lighting</h4>
																			<p>Learn how to use light to improve your
																				output.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'ergo-accessories-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="500"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Accessories.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment-Accessories" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'ergo-accessories-a45'}">
																			<h4>Ergo accessories</h4>
																			<p>It’s all in the details. Learn how
																				accessories help.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="wpb_column vc_column_container vc_col-sm-4">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div
																		class="wpb_single_image wpb_content_element vc_align_left">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'mobile-devices-a45'}">
																				<div
																					class="vc_single_image-wrapper   vc_box_border_grey">
																					<img width="750" height="501"
																						src="../../assets/images/airbnb/Airbnb-Equipment-Mobile-Devices.jpg"
																						class="vc_single_image-img attachment-full"
																						alt="" loading="lazy"
																						title="Airbnb---Equipment---Mobile-Devices" />
																				</div>
																			</router-link>
																		</div>
																	</div>

																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<router-link :to="{ name: 'mobile-devices-a45'}">
																			<h4>Mobile devices</h4>
																			<p>Ergo includes your mobile devices. Learn
																				more.</p>
																			</router-link>

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="vc_empty_space" style="height: 32px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



					</div>
				</div>
			

			</div>
		</div>
	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'lighting-a45',
	}
</script>